import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {
  UserPlusIcon,
  Cog6ToothIcon,
  AdjustmentsHorizontalIcon,
  ChartBarIcon,
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import AccuroAutomationButton from '../integrations/Accuro/AccuroAutomationButton';

/**
 * This component renders the admin menu for users with admin rights.
 * It dynamically displays menu items based on the user's permissions.
 */

function AdminMenu({
  isAdmin, isCustomerAdmin, isAccuroAdmin, canCreateUsersForSites,
  toastSetSuccessNotification, toastSetErrorNotification,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path) => {
    navigate(path);
    handleClose();
  };

  if (!(isAdmin || isCustomerAdmin || canCreateUsersForSites || isAccuroAdmin)) {
    return null;
  }

  const menuItems = [
    {
      condition: canCreateUsersForSites,
      label: 'Create New User',
      dataTestId: 'createNewUserItem',
      action: () => handleNavigate('/user/create'),
      icon: <UserPlusIcon className="nav-bar-icon" />,
    },
    {
      condition: isAdmin,
      label: 'Customer Settings',
      dataTestId: 'customerSettingsItem',
      action: () => handleNavigate('/customer'),
      icon: <Cog6ToothIcon className="nav-bar-icon" />,
    },
    {
      condition: isAdmin || isCustomerAdmin,
      label: isAdmin ? 'Site Settings' : 'User Management',
      dataTestId: 'siteSettingsItem',
      action: () => handleNavigate('/site'),
      icon: <AdjustmentsHorizontalIcon className="nav-bar-icon" />,
    },
    {
      condition: isAdmin, // TODO: potentially limit to superAdmin only
      label: 'Analytics',
      dataTestId: 'analyticsItem',
      action: () => handleNavigate('/analytics'),
      icon: <ChartBarIcon className="nav-bar-icon" />,
    },
  ];

  return (
    <>
      <IconButton
        size="large"
        onClick={handleMenu}
        sx={{ borderRadius: '5%', color: 'black' }}
        data-testid="adminMenuButton"
      >
        <Typography sx={{ fontWeight: '500' }}>Admin</Typography>
        <KeyboardArrowDownRoundedIcon />
      </IconButton>
      <Menu
        id="admin-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems
          .filter((item) => item.condition)
          .map((item) => (
            <MenuItem
              sx={{ my: 0.5 }}
              key={item.label}
              onClick={item.action}
              data-testid={item.dataTestId}
            >
              {item.icon}
              {item.label}
            </MenuItem>
          ))}
        {isAccuroAdmin && (
          <MenuItem onClick={handleClose}>
            <AccuroAutomationButton
              setErrorMessage={toastSetErrorNotification}
              setSuccessMessage={toastSetSuccessNotification}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

AdminMenu.propTypes = {
  isAdmin: PropTypes.bool,
  isCustomerAdmin: PropTypes.bool,
  isAccuroAdmin: PropTypes.bool,
  canCreateUsersForSites: PropTypes.bool,
  toastSetSuccessNotification: PropTypes.func.isRequired,
  toastSetErrorNotification: PropTypes.func.isRequired,
};

AdminMenu.defaultProps = {
  isAdmin: false,
  isCustomerAdmin: false,
  isAccuroAdmin: false,
  canCreateUsersForSites: false,
};

export default AdminMenu;
