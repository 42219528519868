import React, { useContext } from 'react';
import { Grid, Box } from '@mui/material';
import CustomAppBar from '../content/Appbar/CustomAppBar';
import AuthContext from '../context/AuthProvider';

export default function AnalyticsHome() {
  const { auth } = useContext(AuthContext);
  const baseIframeSrc = process.env.REACT_APP_ANALYTICS_URL;

  const iframeSrc = auth?.accessToken
    ? `${baseIframeSrc}?token=${encodeURIComponent(auth.accessToken)}`
    : baseIframeSrc;

  return (
    <Box sx={{ height: '100vh', width: '100vw' }}>
      <Grid container sx={{ height: '100%', width: '100%' }}>
        <Grid item sx={{ width: '100%' }}>
          <CustomAppBar
            siteSelectorOn={false}
            name="Analytics Page"
          />
        </Grid>
        <Grid item sx={{ flexGrow: 1, padding: '10px 30px' }}>
          <iframe
            title="analytics-dashboard"
            style={{
              background: '#F1F5F4',
              border: 'none',
              borderRadius: '2px',
              boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)',
              width: '100%',
              height: '100vh',
            }}
            src={iframeSrc}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
